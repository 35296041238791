import React from "react"
import { graphql } from "gatsby"
import Helmet from "react-helmet"
import get from "lodash/get"
import Layout from "../components/layout"
import { GatsbyImage } from "gatsby-plugin-image"
import AdvancedBuildsListing from "../components/AdvancedBuildsListing"
import WithBuildQuery from "../components/contexts/BuildQuery/WithBuildQuery"
import { FiftyFifty } from "../components/ReponsiveLayouts"
import LongtextMarkdown from "../components/LongtextMarkdown"
import CenterColumn from "../components/CenterColumn"
import { Box } from "@mui/system"

const BuilderFullPageTemplate = props => {
  const builder = get(props, "data.contentfulBuilder")
  const siteTitle = get(props, "data.site.siteMetadata.title")

  return (
    <Layout breadcrumbs={[{ url: "/builders", title: "Builders" }]}>
      <Helmet title={`${builder.name} | ${siteTitle}`} />
      <CenterColumn>
        <FiftyFifty>
          {builder.picture && (
            <GatsbyImage
              flush
              image={builder.picture.gatsbyImageData}
              alt={builder.name}
            />
          )}
          <Box>
            <h2>{builder.name}</h2>
            {builder.age && (
              <div>
                <strong>Age: </strong>
                {builder.age}
              </div>
            )}
            <LongtextMarkdown value={builder.bio} />
          </Box>
        </FiftyFifty>
        <h2>{builder.name}'s Builds</h2>

        <WithBuildQuery limit={9} defaultBuilderId={builder.id} ajax={true}>
          <AdvancedBuildsListing />
        </WithBuildQuery>
      </CenterColumn>
    </Layout>
  )
}

export default BuilderFullPageTemplate

export const pageQuery = graphql`
  query BuilderBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBuilder(slug: { eq: $slug }) {
      name
      id: contentful_id
      age
      bio {
        childMarkdownRemark {
          html
        }
      }
      picture {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`
